import React from 'react'

const FakeCall = () => {
  return (
    <div className='container'>
      <h1 className="h1">Searched request Unavailable</h1>
      <h1 className="h1">Try given requests</h1>
    </div>
  )
}

export default FakeCall
